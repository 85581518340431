// 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd',
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card_btts';
import './Card_btts.css'; // Import styles

function Yesterday() {
  const [yesterdayData, setYesterdayData] = useState(null);

  useEffect(() => {
    const fetchYesterdayData = async () => {
      try {
        const response = await axios.post(
          'https://total93admin.com/get_prediction_btts_list',
          {
            type: 'single_btts',  // Выбираем нужный тип матчей
            result: false,
            day: "today"        // Если нужны прошедшие матчи - ставим true
          },
          {
            headers: {
              Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd', // Замени на свой токен
              'Content-Type': 'application/json',
            },
          }
        );

        setYesterdayData(response.data.events); // Обновляем состояние
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      }
    };

    fetchYesterdayData();
  }, []);

  return (
    <div>
      {Array.isArray(yesterdayData) ? (
        yesterdayData.map((event) => (
          <Card key={event.id} data={event} />
        ))
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Yesterday;
