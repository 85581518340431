import React, { useRef } from 'react';
import moment from 'moment';

const Card = ({ data }) => {
  const cardRef = useRef(null); // Define the cardRef variable using useRef

  const {
    date,
    time,
    status,
    minute,
    localteam_score,
    visitorteam_score,
    localTeam,
    visitorTeam,
    league_name,
    match_country_name,
    match_country_image_path,
    league_logo_path,
    localTeam_logo,
    visitorTeam_logo,
    rez_1_score,
    rez_3_score,
    rez_2_score,
    prediction,
  } = data;

  const formattedDateTime = moment(`${date}${time}`, 'DD-MM-YYYY HH:mm').format('DD MMM HH:mm');

  let scoreBlock = null; // Declare the scoreBlock variable and initialize it to null

  if (status === 'Not started') {
    scoreBlock = (
      <div className= 'score-vertical-vs'></div>
    );
  } else {
    scoreBlock = (
      <div className='score-vertical'>
        <div className='score-team-1'>{localteam_score}</div>
        <div className='divider-3'></div>
        <div className='score-team-2'>{visitorteam_score}</div>
      </div>
    );
  }

  let statusBlock = null; // Declare the scoreBlock variable and initialize it to null
  
  if (status === 'Not started') {
    statusBlock = (
      <div className='date-live'>
          <div className="date-time">{formattedDateTime}</div>
          <div className='live'>
            <div className="live-text">{status}</div>
          </div>
        </div>
    );
  } else if (status === 'Live' || status === 'Half Time') {
    statusBlock = (
      <div className='date-live'>
        <div className="date-time">{formattedDateTime}</div>
        <div className='status-live'>
          <div className="live-text-online">{status}-{minute}'</div>
        </div>
      </div>
    );
  } else if (status === 'Finished' || status === 'Postponed' || status === 'Abandoned' || status === 'Abandoned' || status === 'After Extra Time' || status === 'After penalty') {
    statusBlock = (
      <div className='date-live'>
          <div className="date-time">{formattedDateTime}</div>
          <div className='status-finished'>
            <div className="finished-text">{status}</div>
          </div>
        </div>
    );
  } else {
    // Default case or additional conditions can be added here
    statusBlock = (
      <div className='default-status'>{status}</div>
    );
  }


  return (
    <div className="card-hot" ref={cardRef}>
    <div className="forground">
      <div className='liga-information-block'>
        <div className='liga-info'>
          <img src={league_logo_path} className="liga-icon" alt="League Icon" />
          <div className='liga-name-and-country-info'>
            <div className="league-name">{league_name}</div>
            <div className='country-info'>
              <img src={match_country_image_path} className='country-flag' alt="Country Flag" />
              <div className="country-name">{match_country_name}</div>
            </div>
          </div>
        </div>
        <div>{statusBlock}</div>
      </div>
      <div className='teams-block'>
        <div className='team1'>
          <div className='team-img-1'>
            <img src={localTeam_logo} className='team-logo-1' alt="Local Team Logo" />
          </div>
          <div className='team-name-1'>{localTeam}</div>
        </div>
        <div>{scoreBlock}</div>
        <div className='team2'>
          <div className='team-img-2'>
            <img src={visitorTeam_logo} className='team-logo-2' alt="Visitor Team Logo" />
          </div>
          <div className='team-name-2'>{visitorTeam}</div>
        </div>
      </div>
      <div className='event-info'>
        <div className='ml-view'>Ai: {prediction}</div>
      </div>
      <div className='pred-block'>
        <div className='pred-data'>
          <div className='pred-elem-1'>
            <div className='pred-text'>{rez_1_score}%</div>
          </div>
          <div className='divider-1'></div>
          <div className='pred-elem-x'>
            <div className='pred-text'>{rez_3_score}%</div>
          </div>
          <div className='divider-2'></div>
          <div className='pred-elem-2'>
            <div className='pred-text'>{rez_2_score}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};


export default Card;



