// 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd',
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from './Card_expert';
import './Card.css'; // Import styles

function Yesterday() {
  const [yesterdayData, setYesterdayData] = useState([]); // Начальное значение — пустой массив
  const [loading, setLoading] = useState(true); // Состояние загрузки

  useEffect(() => {
    const fetchYesterdayData = async () => {
      try {
        const response = await axios.post(
          'https://total93admin.com/prediction_events_all_in_one_expert',
          {
            type: 'single_all_in_one',  
            closest: true       
          },
          {
            headers: {
              Authorization: 'Bearer =TxcSDFCXVRGVESFSFRgrs34fsdfsd',
              'Content-Type': 'application/json',
            },
          }
        );

        console.log("API Response:", response.data); // Логируем ответ API

        if (response.data.event) {
          setYesterdayData([response.data.event]); // Делаем объект массивом
        } else {
          console.error("No event data received:", response.data);
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных:', error);
      } finally {
        setLoading(false); // Отключаем загрузку после запроса
      }
    };

    fetchYesterdayData();
  }, []);

  return (
    <div>
      {console.log("yesterdayData:", yesterdayData)} {/* Проверяем, что в стейте */}
      {loading ? (
        <p>Loading...</p>
      ) : yesterdayData.length > 0 ? (
        yesterdayData.map((event) => (
          <Card key={`${event.date}-${event.time}`} data={event} />
        ))
      ) : (
        <p>No matches found.</p> // Сообщение, если данных нет
      )}
    </div>
  );  
}

export default Yesterday;
